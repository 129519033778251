import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  Switch,
  Icon,
} from "@chakra-ui/react";
import { mainColor } from "./LandingPage";
import { useState } from "react";
import { FaPlus } from "react-icons/fa";

const PriceSection = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const togglePricing = () => setIsAnnual((prev) => !prev);

  const monthlyPrice = (
    <Text as="b" color="brand.2" fontSize="3xl">
      2 999 Ft
    </Text>
  );
  const discountedPrice = (
    <Box>
      <Text as="del" fontSize="xl" color="brand.2">
        35 988 Ft{" "}
      </Text>
      <Text>helyett 29 999 Ft</Text>
    </Box>
  );

  const featuresList1 = [
    "Korlátlan szolgáltatás létrehozása",
    "Korlátlan időpont létrehozása",
    "Email értesítő a foglalásokról",
    "Emlékeztető email",
    "Google Naptár szinkronizáció",
    "Egyedi Google Meet link generálás",
    "Ügyfélkezelés",
    "Személyre szabott megjelenés",
    "Vállalkozás/szolgáltatások bemutatása",
    "Blog poszt készítés",
    "Email küldés",
    "Egyedi kérdőív készítés",
    "Saját Domain használat, vagy egyedi URL link, ingyenes SSL tanúsítvány",
  ];

  return (
    <Box id="pricing" p={{ base: 0, md: 8 }} bg="white">
      <Flex direction="column" align="center">
        <Text fontSize="4xl" fontWeight="bold" color={mainColor} mb={4}>
          Árak
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color={mainColor} mb={4}>
          Próbáld ki ingyen 14 napig!
        </Text>

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="flex-start"
          justify="center"
          maxW="7xl"
          mx="auto"
          mb={30}
        >
          <Box
            w={{ base: "100%", md: "45%" }}
            p={4}
            bg="gray.50"
            borderRadius="lg"
            boxShadow="md"
          >
            <Heading size="md" mb={4}>
              Csomag tartalma:
            </Heading>

            <List spacing={3} mb={8}>
              {featuresList1.map((feature, index) => (
                <ListItem key={index}>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  {feature}
                </ListItem>
              ))}
            </List>
            <Text fontSize="2xl">Bónusz:</Text>
            <Box display="flex" mb={2}>
              <Icon as={FaPlus} w={10} h={5} color={mainColor} />
              <Text>
                14 napig ingyenesen kipróbálható, kártyaadat megadása nélkül
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Icon as={FaPlus} w={10} h={5} color={mainColor} />
              <Text>
                Egyszeri ingyenes 30 perces egyéni konzultáció, amennyiben
                elakadtál valamiben
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Icon as={FaPlus} w={10} h={5} color={mainColor} />
              <Text>
                Egyszeri ingyenes 45 perces segítségnyújtás egyéni foglalási
                oldal beállításokhoz.
              </Text>
            </Box>
            <Box display="flex" mb={2}>
              <Icon as={FaPlus} w={10} h={5} color={mainColor} />
              <Text>
                Weboldal, HAMAROSAN
              </Text>
            </Box>
          </Box>

          <Box
            w={{ base: "100%", md: "45%" }}
            p={4}
            bg="white"
            borderRadius="lg"
            boxShadow="md"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={6}
            >
              <Heading size="lg">Válaszd ki a csomagot</Heading>
              <Box display="flex">
                <Text>Havi</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={isAnnual}
                  onChange={togglePricing}
                  mr={2}
                  ml={2}
                />
                <Text>Éves</Text>
              </Box>
            </Box>

            <Box
              shadow="lg"
              p={6}
              borderRadius="md"
              borderWidth={1}
              borderColor="gray.200"
              mb={4}
            >
              <Heading color="brand.1" fontSize="xl">
                {isAnnual ? "Éves Pro Csomag" : "Havi Pro Csomag"}
              </Heading>
              <Flex
                mt={4}
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Text as="b" color="brand.2" fontSize="3xl">
                  {isAnnual ? discountedPrice : monthlyPrice}
                </Text>
                <Text fontSize="2xl" pt={4}>
                  {isAnnual ? "Évente" : "Havonta"}
                </Text>
                <Text color="brand.2" pt={4}>
                  Az ár tartalmazza az ÁFA-t.
                </Text>
              </Flex>
              <Divider my={4} />
              <Box
                as="button"
                height="40px"
                w="100%"
                transition="all 0.2s"
                border="1px"
                borderColor="brand.1"
                borderRadius="8px"
                fontSize="14px"
                fontWeight="semibold"
                bg="brand.1"
                _hover={{ bg: "brand.2" }}
                _active={{
                  bg: "brand.2",
                  transform: "scale(0.98)",
                  borderColor: "brand.2",
                }}
                _focus={{
                  boxShadow: "0 0 1px 2px rgba(88, 144, 255, .75)",
                }}
                onClick={() =>
                  window.open("https://app.wobble.hu/signup_page", "_blank")
                }
              >
                Kipróbálom
              </Box>
            </Box>
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
};

export default PriceSection;
