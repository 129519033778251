import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { greyColor, mainColor, darkColor } from "./LandingPage";
import {
  ArrowForwardIcon,
  CheckCircleIcon,
  QuestionIcon,
} from "@chakra-ui/icons";

const MainSection = () => {
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  return (
    <Box>
      <Flex
        direction="column"
        align="center"
        justify="center"
        bg={greyColor}
        color="black"
        minH="60vh"
        clipPath="polygon(0 0, 100% 0, 100% 75%, 0 100%)"
        textAlign="center"
        px={4}
        py={{ base: 16, md: 24 }}
        bgGradient="radial(rgba(0, 113, 136, 0.2) 1.85px, rgba(250, 250, 250, 0.2) 1.85px)"
        bgSize="37px 37px"
      >
        <Heading as="h1" fontSize={{ base: "2xl", md: "4xl" }} color="black">
          Egy platform, amire szükséged van az induláshoz:
        </Heading>
        <Text fontSize={{ base: "md", md: "xl" }} mt={4} color="gray.700">
          Időpontfoglalás, online jelenlét, és ügyfélkezelés - mindezt egy
          felületen!
        </Text>
        <Link href="https://app.wobble.hu/signup_page" isExternal>
          <Box
            as="button"
            height="40px"
            w="200px"
            lineHeight="1.2"
            transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
            border="1px"
            px="8px"
            mt={8}
            borderRadius="5px"
            fontSize="16px"
            fontWeight="semibold"
            bg={mainColor}
            borderColor={mainColor}
            color="white"
            _hover={{ bg: darkColor }}
            _active={{
              bg: darkColor,
              transform: "scale(0.98)",
              borderColor: darkColor,
            }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
            }}
          >
            Megnézem
            <ArrowForwardIcon />
          </Box>
        </Link>
      </Flex>

      <Box
        bg="gray.100"
        py={12}
        px={{ base: 4, md: 8 }}
        rounded="md"
        shadow="lg"
        maxW="container.md"
        mx="auto"
      >
        <Heading as="h1" size="xl" textAlign="center" mb={6}>
          Kezdd el vállalkozásod digitalizálását a{" "}
          <Text as="span" color={mainColor} fontFamily="'Lato', sans-serif">
            Wobble
          </Text>
          -lel!
        </Heading>

        <VStack spacing={4} align="start" mb={8}>
          <Heading as="h2" size="md" color="gray.600">
            Probléma:
          </Heading>
          <Text fontSize="lg">
            Kezdő vállalkozóként rengeteg kihívással nézel szembe:
          </Text>
          <List spacing={3} pl={4}>
            {[
              "Hogyan hozz létre professzionális online jelenlétet magas költségek nélkül?",
              "Hogyan vonzz ügyfeleket és kezeld hatékonyan a foglalásaikat?",
              "Hogyan maradj kapcsolatban az ügyfelekkel anélkül, hogy órákat töltenél marketinggel?",
              "Hogyan tudj meg minél több információt ügyfeledről már a foglaláskor?",
            ].map((item, index) => (
              <ListItem key={index}>
                <ListIcon as={QuestionIcon} color="blue.500" />
                {item}
              </ListItem>
            ))}
          </List>
        </VStack>

        <Divider />

        <VStack spacing={4} align="start" mt={8} mb={8}>
          <Heading as="h2" size="md" color="gray.600">
            Megoldás:
          </Heading>
          <Text fontSize="lg">
            A Wobble egy minden az egyben platform, amely megoldást nyújt a
            legfontosabb kihívásaidra:
          </Text>
          <List spacing={3} pl={4}>
            {[
              [
                "Időpontfoglalás",
                "Az ügyfeleid gyorsan és egyszerűen foglalhatnak.",
              ],
              [
                "Online jelenlét",
                "Online megjelenés, ahol bemutathatod magad és a szolgáltatásaid.",
              ],
              [
                "Email marketing",
                "Automatikus vagy manuális email küldés a bizalom növeléséhez.",
              ],
              ["Blogolás", "Tartalommarketing a szakértői imázshoz."],
              ["Ügyfélkezelés", "Ügyfél nyilvántartás papirok nélkül."],
              ["ÚJ! Kérdőív", "Szolgáltatáshoz egyedi kérdőív."]
            ].map(([title, description], index) => (
              <ListItem key={index}>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                <Text as="b">{title}:</Text> {description}
              </ListItem>
            ))}
          </List>
        </VStack>

        <Divider />

        <VStack spacing={4} align="start" mt={8} mb={8}>
          <Heading as="h2" size="md" color="gray.600">
            Bónuszok:
          </Heading>
          <List spacing={3} pl={4}>
            {[
              
              [
                "Ingyenes próbaidőszak",
                "14 napig ingyenesen kipróbálható, kártyaadat megadása nélkül",
              ],
              [
                "Elakadtam",
                "Ingyenes 30 perces egyéni konzultáció, amennyiben elakadtál valamiben",
              ],
              [
                "Indulási bónusz",
                "Ingyenes 45 perces egyéni foglalási oldal beállítások.",
              ],
              [
                "+1",
                "Weboldal! HAMAROSAN",
              ],
            ].map(([title, description], index) => (
              <ListItem key={index}>
                <Badge colorScheme="blue" mr={2}>
                  {title}:
                </Badge>
                {description}
              </ListItem>
            ))}
          </List>
        </VStack>

        <Divider />

        {/* CTA Section */}
        <VStack spacing={4} align="center" mt={8}>
          <Text fontSize="lg" textAlign="center">
            Ennyi minden funkció a vállalkozásod digitalizációjához csupán havi 2 990 Ft-ért!
            Ne várj tovább az indulással! Próbáld ki a Wobble-t 14 napig
            teljesen ingyen!
          </Text>
          <Link href="https://app.wobble.hu/signup_page" isExternal>
            <Button colorScheme="teal" size={buttonSize} px={10}>
              Kipróbálom <ArrowForwardIcon />
            </Button>
          </Link>
        </VStack>
      </Box>
    </Box>
  );
};

export default MainSection;
