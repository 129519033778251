import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { mainColor } from "./LandingPage";
import CircleWithNumber from "./CircleWithNumber";
import image from "../../assets/images/3stepsImage.jpg";
import { steps_image1 } from "../../assets/images/steps_image1";
import { steps_image2 } from "../../assets/images/steps_image2";
import { steps_image3 } from "../../assets/images/steps_image3";

const TreeStepsSection = () => {
  const displayMode = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });
  return (
    <Box
      id="integration"
      p={{ base: 8, md: 8, lg: 12 }}
      position="relative"
      width="100%"
      bg="white"
      scrollBehavior="smooth"
    >
      <Box
        position="relative"
        backgroundSize="cover"
        backgroundPosition="center"
        zIndex={2}
        w="100%"
        py={8}
      >
        <Center>
          <Heading textAlign="center" color={mainColor} as="h1">
            <Text fontSize="4xl" fontWeight="bold" color={mainColor} mb={4}>
              Hogyan működik?
            </Text>
            Online időpontfoglalás egyszerűen, 3 lépéses beállítással
          </Heading>
        </Center>
      </Box>
      <Box
        display={displayMode === "desktop" ? "block" : "flex"}
        flexDirection={displayMode === "tablet" ? "row" : "column"}
        alignItems="center"
        justifyContent="center"
      >
        {displayMode === "desktop" && (
          <Box
            position="relative"
            backgroundSize="cover"
            backgroundPosition="center"
            zIndex={2}
          >
            <Center>
              <Image src={image} alt="Responsive Image" />
            </Center>
          </Box>
        )}
        {displayMode !== "desktop" && (
          <Box p={4}>
            <Box
              display={displayMode === "tablet" ? "flex" : "block"}
              flexDirection={displayMode === "tablet" ? "row" : "column"}
              alignItems="left"
              justifyContent="left"
              mb={4}
            >
              <Box w={"70%"}>{steps_image1}</Box>
              <Stack>
                <HStack>
                  <CircleWithNumber number={1} />
                  <Text
                    mt={displayMode !== "tablet" ? 4 : 0}
                    fontSize={displayMode === "tablet" ? 18 : 24}
                    textAlign="left"
                    width={{ base: "80%", md: "auto" }}
                  >
                    lépés: Állítsa be az elérhetőségét
                  </Text>
                </HStack>
                <Text
                  ml={displayMode === "tablet" ? 4 : 0}
                  mt={displayMode !== "tablet" ? 4 : 0}
                >
                  Hozd létre az általad kínált szolgáltatásokat és állítsd be az
                  időintervallumokat. Annyi szolgáltatást adhatsz meg, amennyit
                  csak szeretnél, és a valós ütemtervedhez igazodva egyedi
                  órákat is beállíthatsz. Nyugi, rendszerünk megelőzi a dupla
                  foglalásokat!
                </Text>
              </Stack>
            </Box>
            <Box
              display={displayMode === "tablet" ? "flex" : "block"}
              flexDirection={displayMode === "tablet" ? "row" : "column"}
              alignItems="left"
              justifyContent="left"
              mb={4}
              h="100%"
            >
              <Box w={displayMode === "tablet" ? "30%" : "50%"}>
                {steps_image2}
              </Box>
              <Stack>
                <HStack>
                  <CircleWithNumber number={2} />
                  <Text
                    mt={displayMode !== "tablet" ? 4 : 0}
                    fontSize={displayMode === "tablet" ? 18 : 24}
                    textAlign="left"
                    width={{ base: "80%", md: "auto" }}
                  >
                    lépés: Oszd meg a foglalási linkedet
                  </Text>
                </HStack>
                <Text
                  ml={displayMode === "tablet" ? 4 : 0}
                  mt={displayMode !== "tablet" ? 4 : 0}
                >
                  Egyszerűen oszd meg a foglalási oldalad URL-jét az
                  ügyfelekkel.
                </Text>
              </Stack>
            </Box>
            <Box
              display={displayMode === "tablet" ? "flex" : "block"}
              flexDirection={displayMode === "tablet" ? "row" : "column"}
              alignItems="left"
              justifyContent="left"
              mb={4}
            >
              <Box w={"70%"}>{steps_image3}</Box>
              <Stack>
                <HStack>
                  <CircleWithNumber number={3} />
                  <Text
                    mt={displayMode !== "tablet" ? 4 : 0}
                    fontSize={displayMode === "tablet" ? 18 : 24}
                    textAlign="left"
                    width={{ base: "80%", md: "auto" }}
                  >
                    lépés: Élvezd a problémamentes online foglalásokat
                    éjjel-nappal
                   (az emberek 55%-a munkaidőn kívül foglal)
                  </Text>
                </HStack>
                <Text
                  ml={displayMode === "tablet" ? 4 : 0}
                  mt={displayMode !== "tablet" ? 4 : 0}
                >
                  Biztosítsd az ügyfeleknek a lehetőséget, hogy bármikor
                  foglalhassanak vagy lemondhassák az időpontokat. Rendszerünk
                  automatikusan küld e-mail emlékeztetőket, így ritkábbak
                  lesznek az elmaradt időpontok és zökkenőmentes lesz az
                  ütemterved.
                </Text>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TreeStepsSection;
